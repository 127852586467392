<template>

  <div class="InfoMain">

    <div class="InfoMain--container">

      <div class="InfoMain--container--left">

        <template v-if="infoMainDisplay[0]">
          <InfoCase 
          :content="content" :index="0" 
          @set-maininfo="setMainInfo"
          @set-cursor-activ="setCursorActiv">
          </InfoCase>
        </template>

      </div>

      <div class="InfoMain--container--right">

        <template v-if="infoMainDisplay[1]">
          <InfoCase 
          :content="content" 
          :index="1" 
          @set-maininfo="setMainInfo"
          @set-cursor-activ="setCursorActiv">
          </InfoCase>
        </template>

      </div>

    </div>

  </div>

</template>

<script>
import InfoCase from '@/components/infomain/InfoCase.vue'

export default {
  name: 'InfoMain',
  props: ['content', 'context', 'infoMainDisplay'],
  data: function(){
    return {
    }
  },
  components: {
    InfoCase
  },
  computed:{


  },
  methods: {

    setCursorActiv: function(val){
      this.$emit('set-cursor-activ', val)
    },

    setMainInfo: function(index){
      // console.log('setMainInfo ', index)
      this.$emit('set-maininfo', index)
    },

  },
  created(){
    // console.log(this.content)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.InfoMain{
  width: 100%;
  height: 100%;

  pointer-events: none;

  z-index: 200;

  &--container{
    display: flex;
    height: 100%;

    &--left{
      width: 50%;
      height: 100%;

      // background-color:yellow;

    }

    &--right{
      width: 50%;
      height: 100%;

      // background-color:white;
    }

  }
  
}

</style>