<template>

  <div class="InfoCase">

    <div class="InfoCase--container" ref="container">

      <div class="InfoCase--container--header" ref="container_header">
      
        <div class="InfoCase--container--header--title" 
        @click="setMainInfo(index)"
        @mouseenter="titleMouseenter()"
        @mouseleave="titleMouseleave()">
        {{index == 0 ? 'Krier' : 'Gatard'}}
        </div>
        <div class="InfoCase--container--header--technical">
    
          <div class="InfoCase--container--header--technical--links"
          @mouseenter="titleMouseenter()"
          @mouseleave="titleMouseleave()">
            <a href="mailto:info@krier-gatard.com">
            info@<br>
            krier-gatard<br>
            .com
            </a>
          </div>
          <div class="InfoCase--container--header--technical--adress">75011 Paris</div>
        </div>

      </div>

      <div class="InfoCase--container--main" @click="setMainInfo(index)"
      :style="{height: textHeight + 'px'}" >

        <div class="InfoCase--container--main--description">
          <div class="InfoCase--container--main--description--fr" v-html="content.description_1"></div>
          <div class="InfoCase--container--main--description--en" v-html="content.description_2"></div>
        </div>

        <div class="InfoCase--container--main--credits" v-html="content.credits"> </div>

      </div>

    </div>

  </div>

</template>

<script>
  

export default {
  name: 'InfoCase',
  props: ['content', 'index','context'],
  data: function(){
    return {
      textHeight: ''
    }
  },
  components: {},
  computed:{


  },
  methods: {

    titleMouseenter: function(){
      this.$emit('set-cursor-activ', false)
    },

    titleMouseleave: function(){
      this.$emit('set-cursor-activ', true)
    },

    setTextHeight: function(){

      let containerHeight = this.$refs.container.getBoundingClientRect().height
      let containerHeaderHeight = this.$refs.container_header.getBoundingClientRect().height

      this.textHeight = containerHeight - containerHeaderHeight - (5/100 * window.innerWidth) 

      // console.log(this.textHeight)

    },

    setMainInfo: function(index){

      // console.log('setMainInfo ', index)

      this.$emit('set-maininfo', index)
      this.$emit('set-cursor-activ', true)
    },

    onResize: function(){
      this.setTextHeight()
    }

  },
  created(){
    // console.log('infocase created')
    window.addEventListener('resize', this.onResize)
  },
  mounted(){
    this.setTextHeight()
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.InfoCase{
  background-color: white;
  pointer-events: auto;

  height: 100%;
  width: 100%;

  z-index: 200;

  &--container{
    padding: var(--home-esp-big);
    padding-bottom: 0vw;
    height: 100%;
    // background-color: red;

    &--header{

      display: flex;
      margin-bottom: 1vw;
      // align-items: flex-end;
      // align-items: center;

      // position: relative;

      &--title{
        font-size: var(--home-font-title);
        line-height: 0.8;
        cursor: pointer;

        // background-color: red;
      }

      &--technical{
        padding-left: var(--home-esp-med);
        // display: flex;
        font-size: var(--info-font-adress);
        // pointer-events: none;



        &--adress{
          padding-top: 0.8vw;
          // position: absolute;
          // bottom: 0;
          pointer-events: none;
        }

        &--links{
          // padding-left: var(--home-esp-big);
          line-height: 1;
          cursor: pointer;
          text-decoration: underline;
          // font-family: 'ital';
        }
      }

    }

    &--main{
      // height: 80%;        /// METHODS PIXEL + RESIZE
      overflow: scroll;

      padding-top: 2vw;
      // background-color: yellow;

      &--description{


        display: flex;
        justify-content: space-between;
        font-size: var(--info-font-description);
        line-height: 1.1;

        margin-bottom: var(--home-esp-big);

        overflow-wrap: anywhere;
        @supports ( hyphens: auto ) {
          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          -ms-hyphens: auto;
        }

        &--fr{
          width: calc(100%/2 - var(--home-esp-big));

          & p{
            padding-bottom: var(--home-esp-med);
          }
        }

        &--en{
          width: calc(100%/2 - var(--home-esp-big));
          font-family: 'ital';

          & p{
            padding-bottom: var(--home-esp-med);


          }
        }
      }

      &--credits{
        column-count: 3;
        font-size: var(--info-font-credits);
        line-height: 1.15;
        padding-bottom: var(--home-esp-big);



        & strong{
          text-decoration: underline;
        }

        & p{
          padding-bottom: var(--home-esp-med);
          break-inside: avoid;
          page-break-inside: avoid;

          & a {
            font-family: 'ital';
          }
        }

      }
    }

  }

}

</style>