<template>
	<div class="Home"
		@mouseenter="setCursurIsInit('enter')"
		@mouseleave="setCursurIsInit('leave')">

		<div class="Home--separator">
			<div class="Home--separator--left"></div>
		</div>

		<div class="Home--cursor">
			<CursorMain :cursorIsActiv="cursorIsActiv" :cursorIsInit="cursorIsInit"></CursorMain>
		</div>

		<div class="Home--initscreen" 
		@click="removeInitscreen()" 
		:style="{'z-index': initscreenIsActive ? '100' : '-1' }">
			<InitScreen :content="page.colors" :initscreenIsActive="initscreenIsActive"></InitScreen>
		</div>

		<div class="Home--info">

			<InfoMain 
			:content="page.info" 
			:infoMainDisplay="infoMainDisplay"
			@set-maininfo="setMainInfo"
			@set-cursor-activ="setCursorActiv">
			</InfoMain>
			
		</div>

		<div class="Home--screensaver"></div>

		<div class="Home--slider">

			<SliderMain 
			:content="page.slider" 
			:initscreenIsActive="initscreenIsActive"
			@set-maininfo="setMainInfo"
			@set-cursor-activ="setCursorActiv">
			</SliderMain>

		</div>


	</div>
</template>

<script>
import SliderMain from '@/components/SliderMain.vue'
import InitScreen from '@/components/InitScreen.vue'
import InfoMain from '@/components/InfoMain.vue'
import clientConfigs from '@/mixins/clientConfigs.js'
import CursorMain from '@/components/CursorMain.vue'


export default {
  name: 'Home',
  mixins: [clientConfigs],
	components: {
		SliderMain,
		InitScreen,
		InfoMain,
		CursorMain
	},
  data: function(){
    return {
      state : this.$store.state,
      initscreenIsActive: true,
      infoMainDisplay: [false, false],
      cursorIsActiv: true,
      cursorIsInit: false
    }
  },
  watch: {},
	computed: {
		page: function () { return this.$store.state},
	},
  methods: {

		setMainInfo: function(index){
			this.infoMainDisplay.forEach((e, i) => {
				if( i == index ){
					this.$set(this.infoMainDisplay, i, !this.infoMainDisplay[index])

				}else {
					this.$set(this.infoMainDisplay, i, false)
				}
			})
		},

		setCursurIsInit: function(val){

			let cursorValue

			if (val == 'enter'){
				cursorValue = true
			}
			if (val == 'leave'){
				cursorValue = false
			}

			this.cursorIsInit = cursorValue
			// console.log('setCursurIsInit', this.cursorIsInit)
		},

		setCursorActiv: function(val){
			
			this.cursorIsActiv = val

			// console.log(this.cursorIsActiv)
		},

		removeInitscreen: function(){
			this.initscreenIsActive = false
		},

		setVideoLoading: function(){

			this.page.loading.forEach((element, index) => {
				index
				let video = document.createElement("video");
				video.src = element
				video.load()			

				// console.log('video ', index, ' loaded?')
			})
		}

  },

	created(){
		// console.log(this.$store)
		// console.log(this.$client)

		// console.log(this.page)

		// this.setVideoLoading()
	},
	mounted(){
		// setTimeout(() => { this.removeInitscreen() }, 1000);
	}
}
</script>

<style lang="scss">

.Home{
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;

	// cursor: var(--cursor-round);
	cursor: none;



	&--cursor{
		position: absolute;
		// width: 100%;
		// height: 100%;
		// z-index: 500;
		// pointer-events: none;
	}

	&--separator{
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 500;
		pointer-events: none;

		&--left{
			height: 100%;
			width: calc(50% - 3px);
			border-right: 3px solid black;
			pointer-events: none;
		}
	}

	&--initscreen{
		position: absolute;
		width: 100%;
		height: 100%;

		// z-index: 100;
	}

	&--slider{
		// position: absolute;
		width: 100%;
		height: 100%;
	}

	&--info{
		position: absolute;
		width: 100%;
		height: 100%;

		z-index: 200;

		pointer-events: none;
	}

}

</style>
