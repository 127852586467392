<template>

  <div class="CursorMain">

    <div class="CursorMain--container" v-show="cursorIsInit? true : false">
      <div class="CursorMain--container--cursor-main" 
      ref="cursor" 
      v-show="cursorIsActiv? true : false"
      :style="{left:cursorX + 'px', top: cursorY + 'px' }">
      </div>

      <div class="CursorMain--container--cursor-second" 
      ref="cursorSecond" 
      v-show="cursorIsActiv? true : false"
      :style="{left:cursorSecondX + 10 + 'px', top: cursorY  + 'px' }">
      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'InfoMain',
  props: ['content', 'cursorIsActiv', 'cursorIsInit'],
  data: function(){
    return {
      cursorX: '',
      cursorY: '',

      cursorSecondX: '',
      cursorSecondY: '',

      displayCount: 1,
      timeIsActiv: true,

      secondCursorIsActiv: true,
      halfWindowWidth: '',
      windowWidth: ''
    }
  },
  watch: {

    // cursorIsInit: function(val){
    //   console.log('watch cursorIsInit', val)
    // },

    displayCount: function(val){

      if (val > 2){
        this.$refs.cursor.classList.remove('isMoving')
        this.$refs.cursorSecond.classList.remove('isMoving')

        if (val > 10){
          // console.log('MAX')
          this.removeTimer()
          this.timeIsActiv = false
        }

      }else {
        this.$refs.cursor.classList.add('isMoving')
        this.$refs.cursorSecond.classList.add('isMoving')
      }
    },

    cursorX: function(val){
      

      if (val > this.halfWindowWidth){

        this.cursorSecondX = val - this.halfWindowWidth

        // console.log(this.halfWindowWidth)

        // console.log(val, this.cursorSecondX)

      }else {

        this.cursorSecondX = val + this.halfWindowWidth

      }

    },
    cursorY: function(val){
      // console.log(val)
      val
    }


  },
  components: {
  },
  computed:{

  },
  methods: {

    mouseMoveListener(e){

      this.cursorX = e.clientX
      this.cursorY = e.clientY

      if (this.displayCount > 10 && this.timeIsActiv == false){
        this.setTimer()
      }
      this.displayCount = 0
    },


    setTimer: function(){
      this.timerInterval = setInterval(this.addTime , 250);
    },
    removeTimer: function(){
      clearInterval(this.timerInterval)
    },
    addTime: function(){
      this.displayCount = this.displayCount + 1
    },


    onResize: function(){
      this.windowWidth = window.innerWidth
      this.halfWindowWidth = this.windowWidth / 2
    }

  },
  created(){
    this.setTimer()
    document.addEventListener('mousemove', this.mouseMoveListener)
  },

  mounted(){
    this.windowWidth = window.innerWidth
    this.halfWindowWidth = this.windowWidth / 2

    window.addEventListener('resize', this.onResize)


    // console.log(this.cursorIsInit)
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.onResize)
  }
  
}
</script>

<style lang="scss">
@import '@/css/variables';

.CursorMain{
  position: relative;
  width: 100%;
  height: 100%;

  pointer-events: none;

  z-index: 1000;
  mix-blend-mode: exclusion;

  &--container{
    z-index: 1000;
    width: 100%;
    height: 100%; 


    &--cursor-main{
      z-index: 1000;
      position: absolute;
      width: 100px;
      height: 100px; 

      border-radius: 50%;

      transform: translate(-50%,-50%);

      // backface-visibility: hidden;
      background-color: white;
      color: white;
      mix-blend-mode: exclusion;

      // margin: -15px 0 0 -15px;

      transition: transform 0.4s ease;

      &.isMoving{
        transform: translate(-50%, -50%) scale(0.8);
      }
    }

    &--cursor-second{
      z-index: 1000;
      position: absolute;
      width: 100px;
      height: 100px; 

      border-radius: 50%;

      transform: translate(-50%,-50%);

      // backface-visibility: hidden;
      background-color: white;
      color: white;
      mix-blend-mode: exclusion;

      // margin: -15px 0 0 -15px;

      transition: transform 0.4s ease;

      &.isMoving{
        transform: translate(-50%, -50%) scale(0.8);
      }
    }



  }
}


</style>